<template>
<div class='page'>
  <div class="banner">
            <div class="w">
                <div class="info">
                    <div class="title">CNC Plastic</div>
                    <p class="title1">Massive range of materials Great surface finishing</p>
                </div>
            </div>
        </div>
  <myhead :hbg="hbg"></myhead>
  <bread :bbg="bbg"></bread> 
  <requiredVal :requiredValData="requiredValData"></requiredVal>
 
<title1 :title="title"></title1> 
<processing :ProcessingList="ProcessingList"></processing>
<specifications :SpecificationsLists="SpecificationsLists"></specifications>
<horizontalbar></horizontalbar>

<display :displaylists="displaylists"></display>
 <pdflist :pdflist="pdflist" ></pdflist>
     <applications3 :ApplicationsLists="ApplicationsLists"></applications3>
       <customers></customers>
</div> 
</template> 
<script>
import myhead from "../../components/myHead.vue";
import bread from '../../components/bread.vue'
import requiredVal from "../../components/requiredVal.vue";
import title1 from "../../components/title1.vue";
import processing from "../../components/Processing.vue";
import horizontalbar from "../../components/Horizontalbar.vue";
import specifications from "../../components/Specifications.vue";
import display from "../../components/Display.vue";
import pdflist from "../../components/pdflist.vue";

import applications3 from "../../components/Applications3.vue";
import customers from "../../components/Customers.vue";
export default {
  data() {
    return {
            hbg:{
        bg:'#ffffff'

    },
    bbg:{
 bg:'#ffffff',
 color:'#000000'
    },
        displaylists:[{
        name:'ABS',
        img:require('../../assets/img/cncplastic1.png')

    },{
        name:'PMMA (Acrylic)',
        img:require('../../assets/img/cncplastic2.png')

    },{
        name:'PC ( Polycarbonate)',
        img:require('../../assets/img/cncplastic3.png')

    },{
        name:'PTFE (Teflon)',
        img:require('../../assets/img/cncplastic4.png')

    },{
        name:'PVC',
        img:require('../../assets/img/cncplastic5.png')

    },{
        name:'PA (Nylon)',
        img:require('../../assets/img/cncplastic6.png')

    }], 
         SpecificationsLists:[{
        name:'STANDARD LEAD TIME',
        info:'3-7 days',
    },{
        name:'STANDARD ACCURACY',
        info:'tolerances are controlled to DIN-2678-1 Fine for metals as standard for CNC prototypes.',
    },{
        name:'INSPECTION REPORTS',
        info:'First article or full products inspection reports can be provided for free, CMM (Coordinate Measuring Machine) measurement can be provided for a fee',
    },{
        name:'MAXIMUM PROCESSING SIZE',
        info:'3500*2700*1200mm',
    },{
        name:'FINISHES',
        info:'All typical finishes can be quoted and performed .',
    },],
        ProcessingList:{
        H1:'ADVANTAGES OF OUR CNC PLASTIC PROCESSING',
        leftTitle2:'CNC Machined parts are precise and have high tolerance specifications; the process itself is fast and a wide range of materials can be used. Due to these qualities, CNC Machining is popular choice in the many industries',
        lists:[{
            info:'Fast and repeatable for low volume manufacturing',
        },{
            info:'Massive range of plastic materials',
        },{
            info:'3-axis, 4-axis, & 5-axis capabilities',
        },{
            info:'Rapid machining offers great surface finishing',
        }],
        srcData:require('../../assets/img/cnc-metalpro.png'),
        imgs:[{
           img: require('../../assets/img/plasticxx101.png'),
        },{
           img: require('../../assets/img/cnc-plasticxx2.png'),
        },{
           img: require('../../assets/img/plasticxx103.png'),
        },]


    },
      title:`CNC plastic processing is one of the main method of prototypes manufacturing, mainly processing ABS, PC, PA, PMMA, POM and other plastic materials into the physical samples we need. </br></br>At HLH, Our CNC machining lab is the perfect complement to our suite of additive manufacturing services. Our skilled programmers and fabricators shore up all of our in-house capabilities, with the focus always being excellent craftsmanship and on-time delivery.`,
          requiredValData:{
        H1:'START MAKINHG TODAY - GET IN TOUCH FOR FREE QUOTE AND PROJECT REVIEW',
        viode:'https://hlhwuhan-cnc.oss-eu-central-1.aliyuncs.com/vedio/o-cnc-suliao2.mp4',
        bg:'#000000'

    },
      ApplicationsLists: {
        H1: "CNC PLASTIC MACHINING APPLICATIONS",
         isshow:true,
        data: [
          {
            img: require("../../assets/img/cnc-metalca1.png"),

            info:  "Manufacturing of parts for the automotive industry, such as tire covers, speedometers, steering wheels."
          },
          {
            img: require("../../assets/img/cnc-metalca2.png"),

            info: "Processing and manufacturing of plastic casings for consumer products such as keyboard and fans."
          },
          {
            img: require("../../assets/img/metalca5.png"),

            info: 
              "Product development of medical equipment, such as ventilators, blood pressure monitors, surgical lighting."
          }
        ]
      },
      pdflist: [
        {
          name: "CNC Machining Materials Spec Sheets",
          view:
            "https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2022/0412/sQtZnTax7H.pdf",
          download:
            "https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2022/0412/zYQa2bHce5.zip"
        },
        {
          name: "CNC Machining Materials Guideline",
          view:
            "https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2022/0412/NBFCDiy8PB.pdf",
          download:
            "https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2022/0412/YFAcZeJBK3.zip"
        },
        {
          name: "CNC Machining Design Guide",
          view:
            "https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2022/0412/26xT5QYnWz.pdf",
          download:
            "https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2022/0412/K66GshThBa.zip"
        },
        {
          name: "HLH Equipment List",
          view:
            "https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2022/0412/P8FHQW35Sp.pdf",
          download:
            "https://hlh-dongguan.oss-cn-shenzhen.aliyuncs.com/quotation/2022/0412/TQszhp8Xds.zip"
        }
      ]
    };
  },
  methods: {},
  mounted() {},
  created() {},
  props: {},
  watch: {},
  components: {
    myhead,
    bread,
    requiredVal,
    // eslint-disable-next-line vue/no-unused-components
    title1,
    processing,
    specifications,
    horizontalbar,
    display,
    pdflist,
    applications3,
    customers
  }
};
</script>
<style scoped lang="scss">
 .banner{
        background: url('~@/assets/img/cnc-plasticbanner.png') no-repeat;
        
  
        width: 100%;
        // height: 560px;
        transition: 0.25s;
    
        padding-top: 70px;
        .w{
         
            display: flex;
            margin: auto;
            // justify-content: flex-end;
            margin-top: 87px;
        }
        .btn {
            display: inline-block;
             padding: 10px 15px;
          
            cursor: pointer;
            border: 1px solid #071930;
            // font-size: 16px;
            text-align: center;

            color: #071930;
            // margin-left:64px ;
        }
        .title1{
            // font-size: 60px;
            margin-bottom: 2vw;
            transition: 0.25s;
            color: #ffffff;
          
      
        }
        .info {
      
    
            font-size: 24px;
            color: #333333;
            // margin-top: 30px;    
            .item{
                display: flex;
                align-items: center;
            
            }
            >p {
                // margin-bottom: 30px;
                img {
                    vertical-align: middle;
                    margin-right: 20px;
                    // width: 36px;
                    // height: 36px;
                    max-width: 36px;
                    min-width: 25px;
                 
                }
                span {
                    vertical-align: middle;
                }
            }
            >.title {
                // font-size: 48px;
                // margin-bottom: 0;
                color: #ffffff;
           transition: 0.25s;
           font-weight:bold;
            }
        }
    }
    
@media screen and (max-width: 760px) {   //最小  min-width="330px"
    .info{
    //   position: absolute;
    // right: 2vw;
    // top: 167px;
  margin-top: 22vw;
    margin-left: -2vw;
    }
    .w{
        width: 90%;
        min-width: 330px;
    }
 
    .title{
           font-size: 40px;
    font-weight: bold;
    margin-bottom: 2vw;
    } 
    .title1{
     font-size: 19px;
    font-weight: bold;
    width: 42vw;
          // line-height: 25px;
    }
    img{
        width: 26px
    }
    .itemT{
        font-size: 14px;
    }
    p{
        margin-bottom:10px ;
    }
    .banner{
        height: 520px;
            background-position: 62% 79% !important;
    }
     .btn{
        font-size: 14px;
        margin-left: 48px;
    }
}

//平板
@media screen and (min-width: 760px) and (max-width: 1020px) {
  
  

    .w{
        width: 90%;
    }
    .title{
        font-size: 60px;
        margin-bottom: 54px;
    }
    .title1{
          font-size: 28px;
    line-height: 40px;
    width: 41vw;
    }
    img{
        width: 30px;
    }
    .itemT{
        font-size: 22px;
    }
    p{
        margin-bottom:30px ;
    }
    .banner{
        height: 620px;
        background-position: center !important;
    }
    .btn{
        margin-left: 64px;
        font-size: 16px;
    }
    .info{
        margin-top: 30px;
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
   
    .w{
        width: 90%;
    }
    .title{
        font-size: 80px;
        margin-bottom: 54px; 
    }
   .title1{
           font-size: 28px;
    width: 36vw;
    line-height: 36px;
    }
    img{
        width: 36px;}
        .itemT{
            font-size: 24px;
        }  p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
        }
}



@media screen and (min-width: 1350px) {  //最大限制   width="1280px"
    .w{
        width: 1200px;
    }
    .title{
        font-size: 100px;
        margin-bottom: 54px;
    }
    .title1{
        font-size: 36px;
    width: 478px;
    line-height: 52px;
    }
    .itemT{
        font-size: 24px;
    }
    img{
        width: 36px;}
        p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
        }
}
</style>